import { DependencyList, useEffect, useMemo, useRef } from 'react';

const debounce = <F extends (...args: any[]) => any>(func: F, waitMilliseconds: number) => {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  return (...args: any[]) => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => func(...args), waitMilliseconds);
  };
};

export const useDebounce = <F extends (...args: any[]) => any>(
  callback: F,
  delay: number,
  deps: DependencyList = []
) => {
  const callbackRef = useRef<F>(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, deps);

  return useMemo(() => debounce((...args) => callbackRef.current(...args), delay), [delay]);
};
