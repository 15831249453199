import { DefaultTheme } from 'styled-components';
import { themeBase, Theme } from '@column/column-ui-kit';

const themeWarmColors = {
  foreground: '#272523',
  background: '#FDFCFC',
  body: '#FCFAF8',
  primary: '#5884F3',
  secondary: '#757575',
  success: '#7CE5A8',
  warning: '#F4DF69',
  danger: '#EC4439',

  black: '#000000',
  white: '#FFFFFF',
  gray: '#575A64',
  blue: '#1E4199',
  cyan: '#71D2F0',
  seafoam: '#44B48B',
  green: '#9CD95D',
  orange: '#EC8439',
  red: '#D64260',
  pink: '#AB4BB1',
  purple: '#845EDB',
};

export const themeWarm: DefaultTheme = new Theme({
  name: 'Warm',
  ...themeWarmColors,
  style: themeBase,
  colors: {},
});
