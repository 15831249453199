import { BaseModel } from './BaseModel';
import { UserRepository, PlatformRepository } from '~/repositories';
import { useSessionStore } from '~/stores/Session';

export type UserType = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  emailState: string;
  dashboardDisplayMode: string;
  defaultPlatformId: string;
  mfaState: string;
};

export type UpdateUser = {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  defaultPlatformId?: string;
};

export class UserModel extends BaseModel {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  emailState: string;
  dashboardDisplayMode: string;
  defaultPlatformId: string;
  mfaState: string;

  constructor(attrs: UserType) {
    super();
    this.id = attrs.id;
    this.firstName = attrs.firstName;
    this.lastName = attrs.lastName;
    this.email = attrs.email;
    this.phoneNumber = attrs.phoneNumber;
    this.emailState = attrs.emailState;
    this.dashboardDisplayMode = attrs.dashboardDisplayMode;
    this.defaultPlatformId = attrs.defaultPlatformId;
    this.mfaState = attrs.mfaState;
  }

  get isEmailVerified() {
    return this.emailState === 'VERIFIED';
  }

  get isEmailPending() {
    return this.emailState === 'VERIFY_IN_PROGRESS';
  }

  get isMfaVerified() {
    return this.mfaState === 'ENABLED';
  }

  get isMfaPending() {
    return this.mfaState === 'SETUP_IN_PROGRESS';
  }

  createVerifyEmail() {
    UserRepository.createVerifyEmail().then(() => {
      this.emailState = 'VERIFY_IN_PROGRESS';
    });
    return this;
  }

  async verifyEmail(code: string) {
    return UserRepository.verifyEmail(code).then(() => {
      this.emailState = 'VERIFIED';
    });
  }

  async setupMfa(reCaptchaToken: string) {
    return UserRepository.setupMfa(reCaptchaToken).then(() => {
      this.mfaState = 'SETUP_IN_PROGRESS';
    });
  }

  async verifyMfa(code: string, reCaptchaToken: string) {
    return UserRepository.verifyMfa(code, reCaptchaToken).then((response) => {
      setTimeout(() => {
        this.mfaState = 'ENABLED';
      }, 0);
      return response;
    });
  }

  async update(attrs: UpdateUser, reCaptchaToken: string) {
    if (attrs.email && this.email !== attrs.email) {
      setTimeout(() => {
        this.emailState = 'NOT_VERIFIED';
      }, 0);
    }
    if (attrs.phoneNumber && this.phoneNumber !== attrs.phoneNumber) {
      setTimeout(() => {
        this.mfaState = 'NOT_ENABLED';
      }, 0);
    }
    if (attrs.firstName) {
      this.firstName = attrs.firstName;
    }
    if (attrs.lastName) {
      this.lastName = attrs.lastName;
    }
    if (attrs.email) {
      this.email = attrs.email;
    }
    if (attrs.phoneNumber) {
      this.phoneNumber = attrs.phoneNumber;
    }
    if (attrs.defaultPlatformId) {
      this.defaultPlatformId = attrs.defaultPlatformId;

      const platformRolesResponse = await UserRepository.platformRoles({ dashboardUserId: this.id });

      const platformRole = platformRolesResponse.platformRoles.find(
        (role) => this.id === role.dashboardUserId && this.defaultPlatformId === role.platformId
      );

      const platform = await PlatformRepository.get(this.defaultPlatformId);

      setTimeout(() => {
        useSessionStore.getState().setPlatform(platform, platformRole);
      }, 0);
    }
    return UserRepository.update({ dashboardUserId: this.id, ...attrs }, reCaptchaToken);
  }
}
