import React from 'react';
import { Input, Dropdown, AmountInput, Chip, CurrenciesUSD, formatNumber } from '@column/column-ui-kit';
import { FormElement, FormLabel, FormText } from '~/styles';
import { DateInput, CopyInput } from '~/components';

export interface GenerateField {
  type?: 'input' | 'date' | 'country' | 'state' | 'amount' | 'amount-text' | 'ach-code' | 'copy' | 'text' | 'chip';
  typeValue?: string;
  countries?: Country[];
  states?: State[];
  label: string;
  value: any;
  newRow?: boolean;
  hide?: boolean;
  isDisabled?: boolean;
  onChange?: (value: any) => void;
  currencyCode?: string;
  disableBefore?: Date;
  disableAfter?: Date;
  fieldRef?: React.RefObject<any>;
}

export const generateFields = <T extends unknown, N extends unknown = void>(
  fields: GenerateField[],
  data: any,
  callback: any,
  nested?: string
) => {
  return fields.map(
    (field: GenerateField, index: number) =>
      !field.hide && (
        <FormElement key={index} newRow={field.newRow}>
          <FormLabel>{field.label}</FormLabel>
          {(!field.type || field.type === 'input') && (
            <Input
              onChange={(value: string) => {
                if (field.onChange) {
                  field.onChange(value);
                }
                return callback(
                  nested
                    ? { ...data, [nested]: { ...data[nested as N], [field.value]: value } }
                    : { ...data, [field.value]: value }
                );
              }}
              value={(nested && data[nested as N] ? data[nested as N][field.value as T] : data[field.value as T]) ?? ''}
              placeholder={field.label}
              isDisabled={field.isDisabled}
            />
          )}
          {field.type === 'date' && (
            <DateInput
              onChange={(value: string | undefined) => {
                if (field.onChange) {
                  field.onChange(value);
                }
                return callback(
                  nested
                    ? { ...data, [nested]: { ...data[nested as N], [field.value]: value } }
                    : { ...data, [field.value]: value }
                );
              }}
              value={nested && data[nested as N] ? data[nested as N][field.value as T] : data[field.value as T]}
              disableBefore={field.disableBefore}
              disableAfter={field.disableAfter}
              isDisabled={field.isDisabled}
            />
          )}
          {field.type === 'country' && (
            <Dropdown
              variant="muted"
              fullWidth
              active={
                (nested && data[nested as N] ? data[nested as N][field.value as T] : data[field.value as T]) ?? ''
              }
              onChange={(value: string | number | undefined) => {
                if (field.onChange) {
                  field.onChange(value);
                }
                return callback(
                  nested
                    ? { ...data, [nested]: { ...data[nested as N], [field.value]: value } }
                    : { ...data, [field.value]: value }
                );
              }}
              options={
                field.countries
                  ? field.countries.map((c) => ({
                      label: c.name,
                      value: c.code,
                    }))
                  : []
              }
              search={field.countries && field.countries.length > 1}
              isDisabled={field.isDisabled}
            />
          )}
          {field.type === 'state' && (
            <Dropdown
              ref={field.fieldRef}
              variant="muted"
              fullWidth
              active={
                (nested && data[nested as N] ? data[nested as N][field.value as T] : data[field.value as T]) ?? ''
              }
              onChange={(value: string | number | undefined) => {
                if (field.onChange) {
                  field.onChange(value);
                }
                return callback(
                  nested
                    ? { ...data, [nested]: { ...data[nested as N], [field.value]: value } }
                    : { ...data, [field.value]: value }
                );
              }}
              options={
                field.typeValue && field.states
                  ? field.states
                      .filter((s) => s.country_code === field.typeValue)
                      .map((s) => ({
                        label: `${s.name} (${s.state_code})`,
                        value: s.state_code,
                      }))
                  : []
              }
              search
              isDisabled={field.isDisabled}
            />
          )}
          {field.type === 'ach-code' && (
            <Dropdown
              variant="muted"
              fullWidth
              active={
                (nested && data[nested as N] ? data[nested as N][field.value as T] : data[field.value as T]) ?? ''
              }
              onChange={(value: string | number | undefined) => {
                if (field.onChange) {
                  field.onChange(value);
                }
                return callback(
                  nested
                    ? { ...data, [nested]: { ...data[nested as N], [field.value]: value } }
                    : { ...data, [field.value]: value }
                );
              }}
              options={['CODE_NONE', 'CCD', 'CTX', 'CIE', 'PPD', 'TEL', 'WEB'].map((code: string) => ({
                label: code === 'CODE_NONE' ? 'None' : code,
                value: code,
              }))}
              isDisabled={field.isDisabled}
            />
          )}
          {field.type === 'amount' && (
            <AmountInput
              onChange={(value?: number) => {
                if (field.onChange) {
                  field.onChange(value);
                }
                return callback(
                  nested
                    ? { ...data, [nested]: { ...data[nested as N], [field.value]: value } }
                    : { ...data, [field.value]: value }
                );
              }}
              value={nested && data[nested as N] ? data[nested as N][field.value as T] : data[field.value as T]}
              onCurrencyCodeChange={(value: string) => {
                if (!field.currencyCode) {
                  return;
                }
                return callback(
                  nested
                    ? { ...data, [nested]: { ...data[nested as N], [field.currencyCode]: value } }
                    : { ...data, [field.currencyCode]: value }
                );
              }}
              currencyCode={
                nested && data[nested as N] ? data[nested as N][field.currencyCode as T] : data[field.currencyCode as T]
              }
              currencyList={CurrenciesUSD}
              isDisabled={field.isDisabled}
            />
          )}
          {field.type === 'copy' && (
            <CopyInput
              value={nested && data[nested as N] ? data[nested as N][field.value as T] : data[field.value as T]}
            />
          )}
          {field.type === 'text' && (
            <FormText>
              {(nested && data[nested as N] ? data[nested as N][field.value as T] : data[field.value as T]) || '-'}
            </FormText>
          )}
          {field.type === 'amount-text' && (
            <FormText>
              {formatNumber(
                (nested && data[nested as N] ? data[nested as N][field.value as T] : data[field.value as T]) || '0'
              )}
            </FormText>
          )}
          {field.type === 'chip' && (
            <FormText>
              <Chip>
                {(nested && data[nested as N] ? data[nested as N][field.value as T] : data[field.value as T]) || '-'}
              </Chip>
            </FormText>
          )}
        </FormElement>
      )
  );
};
