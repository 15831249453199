import { createApiHook } from './utils/createApiHook';
import { BankAccountWithDetails } from '~/typings/API';
import {
  BankAccountRepository,
  BankAccountListResponse,
  BankAccountFilterParams,
  GetBankAccountRequest,
  AccountNumber,
  GetAccountNumberRequest,
} from '~/repositories/BankAccountRepository';

export const useBankAccounts = createApiHook<BankAccountListResponse, BankAccountFilterParams>(
  BankAccountRepository.getAll,
  {
    triggerAutomatically: true,
    triggerOnSessionStoreSubscribe: true,
    includeQueryParams: true,
  }
);

export const useLazyBankAccounts = createApiHook<BankAccountListResponse, BankAccountFilterParams>(
  BankAccountRepository.getAll,
  {
    includeQueryParams: true,
  }
);

export const useBankAccount = createApiHook<BankAccountWithDetails, GetBankAccountRequest>(BankAccountRepository.get);

export const useAccountNumber = createApiHook<AccountNumber, GetAccountNumberRequest>(
  BankAccountRepository.getAccountNumber
);
