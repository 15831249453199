import React, { useRef } from 'react';
import styled from 'styled-components';
import { Button, Icon, Dropdown, Tooltip, formatNumber } from '@column/column-ui-kit';
import { useNavigate } from 'react-router-dom';
import { BankAccountRepository } from '~/repositories';
import {
  EmptyState,
  PaginationWrapper,
  PaginationWrapperRefProps,
  PopoverFilterEntry,
  TableColumn,
  PageHeader,
} from '~/components';
import { ROUTE } from '~/app/routes';
import { useAlertStore } from '~/stores/Alert';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { RelativeTime } from '~/elements';

const Balance = styled.div`
  display: flex;
  gap: 20px;
  line-height: 24px;
  white-space: nowrap;
  font-size: 13px;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};

  strong {
    font-weight: 500;
    color: ${({ theme }) => theme.secondary.background};
  }
`;

const Overdraftable = styled(Tooltip)`
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: top;

  svg {
    --icon-size: 16px;
    --icon-color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  }
`;

const AccountNameTooltip = styled(Tooltip)`
  display: inline-block;
`;

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const StyledDropdown = styled(Dropdown)`
  margin: -10px 0;
`;

export const PageBankAccounts: React.FC = () => {
  const openAlert = useAlertStore((state) => state.openAlert);
  const currentUser = useSessionStore((state) => state.currentUser);
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const navigate = useNavigate();
  const paginationRef = useRef<PaginationWrapperRefProps>(null);

  const handleDelete = (entry: any) => {
    openAlert({
      headline: 'Delete bank account',
      text: 'This action cannot be undone.',
      submitText: 'Delete',
      callback: () => {
        if (!currentUser) {
          return;
        }
        BankAccountRepository.delete(entry.id)
          .then(() => {
            addSuccessNotification({
              content:
                entry.displayName || entry.description
                  ? `${entry.displayName || entry.description} deleted`
                  : 'Deleted',
              display: 'page',
            });
            if (paginationRef.current) {
              paginationRef.current.fetch();
            }
          })
          .catch((error) => {
            addDangerNotification({
              content: error.message,
              display: 'page',
            });
          });
      },
    });
  };

  const columns: TableColumn[] = [
    {
      Header: 'Name',
      accessor: 'name',
      width: 'minmax(300px, auto)',
      Cell: (current) => (
        <NameWrapper>
          {typeof current.value[0] === 'string' && current.value[0].length > 0 ? (
            <AccountNameTooltip delay={500} placement="top-start" content={current.value[1]}>
              {current.value[0]}
            </AccountNameTooltip>
          ) : (
            current.value[1]
          )}
          {current.value[2] && (
            <Overdraftable content="Overdraftable">
              <Icon.OverdraftMoney />
            </Overdraftable>
          )}
        </NameWrapper>
      ),
    },
    {
      Header: 'Balance',
      accessor: 'balance',
      width: 'minmax(min-content, 100px)',
      Cell: (current) => (
        <Balance>
          <div>
            <strong>{formatNumber(current.value[0])}</strong> Available
          </div>
        </Balance>
      ),
    },
    {
      Header: 'Created',
      accessor: 'created',
      sortType: 'datetime',
      width: 'min-content',
      Cell: (current) => <RelativeTime timestamp={current.value} />,
    },
    {
      Header: '',
      width: 'min-content',
      accessor: 'action',
    },
  ];

  const filter: PopoverFilterEntry[] = [
    {
      id: 'isOverdraftable',
      label: 'Overdraftable',
      type: 'boolean',
    },
    {
      id: 'description',
      label: 'Description',
      type: 'input',
    },
    {
      id: 'type',
      label: 'Type',
      type: 'select',
      list: [
        {
          value: 'UNKNOWN',
          label: 'Unknown',
        },
        {
          value: 'OVERDRAFT_RESERVE',
          label: 'Overdraft Reserve',
        },

        {
          value: 'CHECKING',
          label: 'Checking',
        },

        {
          value: 'PROGRAM_RESERVE',
          label: 'Program Reserve',
        },
      ],
    },
    {
      id: 'created',
      label: 'Created',
      type: 'date',
    },
  ];

  const handleFetch = async (params: any) => {
    return BankAccountRepository.getAll(params).then((response) => {
      const entries: any = [];
      if (response.bankAccounts.length) {
        response.bankAccounts.map((entry: any) => {
          entries.push({
            id: entry.id,
            name: [entry.displayName, entry.description, entry.isOverdraftable],
            balance: [entry?.balances?.availableAmount ?? 0, entry?.balances?.pendingAmount ?? 0],
            created: entry.createdAt,
            action: (
              <StyledDropdown
                isInside
                positionRight
                options={[
                  {
                    label: 'Edit',
                    onClick: () => navigate(`${ROUTE.BANK_ACCOUNTS}/edit/${entry.id}`),
                  },
                  {
                    label: 'Delete',
                    isDanger: true,
                    onClick: () => handleDelete(entry),
                  },
                ]}
                variant="dots"
              />
            ),
          });
        });
      }
      return { entries, hasMore: Boolean(response?.hasMore) };
    });
  };

  return (
    <>
      <PageHeader text="Accounts" border={false}>
        <Button
          onClick={() => navigate(ROUTE.COUNTERPARTIES)}
          icon={<Icon.UsersArrow />}
          size="small"
          variant="secondary"
        >
          Counterparties
        </Button>
        <Button onClick={() => navigate(`${ROUTE.BANK_ACCOUNTS}/edit`)} icon={<Icon.Plus />} size="small">
          Create Account
        </Button>
      </PageHeader>

      <PaginationWrapper
        tableId="accounts"
        ref={paginationRef}
        fetch={handleFetch}
        columns={columns}
        filter={filter}
        empty={
          <EmptyState headline="No accounts found" text="Add your first bank account to get started.">
            <Button onClick={() => navigate(`${ROUTE.BANK_ACCOUNTS}/edit`)} size="small" icon={<Icon.Plus />}>
              Create Account
            </Button>
          </EmptyState>
        }
        rowClick={(row) => {
          navigate(`${ROUTE.BANK_ACCOUNTS}/edit/${row.original.id}`);
        }}
      />
    </>
  );
};
