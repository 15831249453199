import React, { useEffect, useState, ReactNode } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  Icon,
  Button,
  Dropdown,
  Input,
  AmountInput,
  DropdownOption,
  Toggle,
  Tooltip,
  formatNumber,
  CurrenciesUSD,
} from '@column/column-ui-kit';
import { EditToolbar, FormElement, FormLabel, Inner, Grid } from '~/styles';
import { PageHeader, RestrictedBanner, SectionHeader } from '~/components';
import { ROUTE } from '~/app/routes';
import { useNotificationStore } from '~/stores/Notification';
import { BankAccountRepository, Loan, LoanRepository } from '~/repositories';
import { CreateLoanDisbursementRequest } from '~/typings/API';
import { useSessionStore } from '~/stores/Session';

interface Params {
  id?: string;
}

const ToggleWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  min-height: 40px;

  svg {
    --icon-size: 18px;
    --icon-color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  }
`;

export const PageLoansDisbursementCreate: React.FC = () => {
  const currentPermission = useSessionStore((state) => state.currentPermission);
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const navigate = useNavigate();
  const { id } = useParams<keyof Params>() as Params;
  const [loan, setLoan] = useState<Partial<Loan>>({});
  const [disbursement, setDisbursement] = useState<Partial<CreateLoanDisbursementRequest>>({});
  const [loanList, setLoanList] = useState<DropdownOption[]>([]);
  const [bankAccountList, setBankAccountList] = useState<DropdownOption[]>([]);

  const breadcrumb: { label: ReactNode; path: string }[] = [
    {
      label: (
        <>
          <Icon.HandCoins />
          Loans
        </>
      ),
      path: ROUTE.LOANS,
    },
  ];

  if (loan?.description) {
    breadcrumb.push({
      label: <>{loan.description}</>,
      path: `${ROUTE.LOANS}/edit/${id}`,
    });
  }

  const handleCreate = () => {
    if (!disbursement.currency) {
      disbursement.currency = 'USD';
    }

    LoanRepository.createDisbursement(disbursement)
      .then((response) => {
        navigate(`${ROUTE.LOANS}/edit/${disbursement.loanId}`);
        addSuccessNotification({
          content: `${response.description} added`,
          display: 'page',
        });
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      });
  };

  const fetchBankAccounts = async (search?: string) => {
    let result: any = [];

    try {
      const response = await BankAccountRepository.getAll({ description: search });
      result = response.bankAccounts;
    } catch (error) {
      addDangerNotification({
        content: error as string,
        display: 'page',
      });
    }

    const entries: DropdownOption[] = [];
    result.map((entry: any) => {
      entries.push({
        label: `${
          entry?.displayName && entry?.description
            ? `${entry.displayName} – ${entry.description}`
            : entry?.description || 'Unnamed'
        } (${formatNumber(entry?.balances?.availableAmount)})`,
        small: entry.id,
        value: entry.id,
      });
    });

    setBankAccountList(entries);
  };

  const fetchLoans = (startingAfter?: string) => {
    LoanRepository.getAll({ startingAfter })
      .then((response) => {
        if (response.loans.length) {
          const entries: DropdownOption[] = startingAfter ? loanList.filter((e) => !e.isLoading) : [];
          response.loans.map((entry) => {
            entries.push({
              label: entry.description,
              value: entry.id,
            });
          });
          if (response.hasMore) {
            entries.push({
              label: 'Loading...',
              isLoading: true,
              value: false,
            });
          }
          setLoanList(entries);
        }
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      });
  };

  const handleScrollEnd = () => {
    const gotLoading = loanList.find((e) => e.isLoading);

    if (gotLoading) {
      const filteredList = loanList.filter((e) => !e.isLoading);
      fetchLoans(filteredList[filteredList.length - 1]?.value);
    }
  };

  useEffect(() => {
    fetchBankAccounts();
    fetchLoans();

    if (!id) {
      return;
    }

    setDisbursement({ ...disbursement, loanId: id });

    LoanRepository.get(id)
      .then((response) => {
        setLoan(response);
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      });
  }, []);

  return (
    <>
      <PageHeader text="Create Disbursement">
        <Button
          onClick={() => navigate(id ? `${ROUTE.LOANS}/edit/${id}` : `${ROUTE.LOANS}/disbursements`)}
          variant="secondary"
          size="small"
        >
          Cancel
        </Button>
        <Button
          onClick={handleCreate}
          isDisabled={currentPermission?.loans !== 'write'}
          size="small"
          icon={<Icon.Plus />}
        >
          Create Disbursement
        </Button>
      </PageHeader>

      {currentPermission?.loans !== 'write' && (
        <Inner pb={0}>
          <RestrictedBanner />
        </Inner>
      )}

      <div>
        <SectionHeader text="Information" />
        <Inner data-disabled={currentPermission?.loans !== 'write'} pt={16}>
          <Grid>
            <FormElement>
              <FormLabel>Description</FormLabel>
              <Input
                onChange={(value: string) => setDisbursement({ ...disbursement, description: value })}
                value={disbursement?.description ?? ''}
                placeholder="Description"
              />
            </FormElement>
            <FormElement>
              <FormLabel>Loan</FormLabel>
              <Dropdown
                fullWidth
                maxWidth="640px"
                active={disbursement.loanId}
                onChange={(value: string) => setDisbursement({ ...disbursement, loanId: value })}
                onScrollEnd={handleScrollEnd}
                options={loanList}
                isDisabled={!!id}
              />
            </FormElement>
            <FormElement>
              <FormLabel>Bank Account</FormLabel>
              <Dropdown
                options={bankAccountList}
                active={disbursement.bankAccountId}
                fullWidth
                maxWidth="640px"
                search
                searchLabel="Search for description"
                onSearchChange={(value: string) => fetchBankAccounts(value)}
                onChange={(value: string) => setDisbursement({ ...disbursement, bankAccountId: value })}
              />
            </FormElement>
            <FormElement>
              <FormLabel>Amount</FormLabel>
              <AmountInput
                onChange={(value?: number) => setDisbursement({ ...disbursement, amount: value?.toString() })}
                onCurrencyCodeChange={(value: string) => setDisbursement({ ...disbursement, currency: value })}
                currencyCode={disbursement.currency}
                currencyList={CurrenciesUSD}
                value={disbursement.amount ? parseInt(disbursement.amount, 10) : undefined}
              />
            </FormElement>
            <FormElement>
              <FormLabel>On Hold?</FormLabel>
              <ToggleWrapper>
                <Toggle
                  isChecked={disbursement.hold}
                  onCheckedChange={(value: boolean) => setDisbursement({ ...disbursement, hold: value })}
                />
                <Tooltip content="If checked, creates a disbursement in a hold state. The disbursement will not be completed until the clear API is called. Disbursements in a hold state may be updated or canceled.">
                  <Icon.CircleQuestionmark />
                </Tooltip>
              </ToggleWrapper>
            </FormElement>
          </Grid>
        </Inner>
      </div>
      <Inner>
        <EditToolbar>
          <Button
            onClick={() => navigate(id ? `${ROUTE.LOANS}/edit/${id}` : `${ROUTE.LOANS}/disbursements`)}
            variant="secondary"
            size="small"
          >
            Cancel
          </Button>
          <Button
            onClick={handleCreate}
            isDisabled={currentPermission?.loans !== 'write'}
            size="small"
            icon={<Icon.Plus />}
          >
            Create Disbursement
          </Button>
        </EditToolbar>
      </Inner>
    </>
  );
};
