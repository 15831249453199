import React, { FC } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Button, Icon } from '@column/column-ui-kit';
import { BankAccountRouteProps } from './Route';
import { EmptyState } from '~/components';
import { ReportingTable } from '~/components/ReportingTable';
import { FeatureFlag, useFeatureFlag } from '~/lib/flags';
import { useModalStore } from '~/stores/Modal';

export const PageBankAccountsStatements: FC = () => {
  const openModal = useModalStore((state) => state.openModal);
  const { bankAccount } = useOutletContext<BankAccountRouteProps>();
  const enableCustomCSVStatements = useFeatureFlag(FeatureFlag.EnableCustomCSVStatements);

  return (
    <ReportingTable
      tableId="statements"
      bankAccountId={bankAccount.id}
      filter={{ category: 'statement' }}
      visibleFiles={['pdf', 'csv']}
      hiddenFiles={['json', 'parquet', 'bai2']}
      empty={<EmptyState headline="No statements found" text="Wait for your first statements to get generated." />}
      action={
        enableCustomCSVStatements && (
          <Button
            icon={<Icon.AnimationDownload />}
            size="small"
            variant="secondary"
            onClick={(e) => {
              e.stopPropagation();
              openModal('CreateStatement', { bankAccount });
            }}
          >
            Export Custom CSV
          </Button>
        )
      }
    />
  );
};
