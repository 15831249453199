import React from 'react';
import { ModalAcceptPlatformInvite } from './AcceptPlatformInvite';
import { ModalAlert } from './Alert';
import { ModalAuthenticate } from './Authenticate';
import { ModalCounterparty } from './Counterparty';
import { ModalPasswordPrompt } from './PasswordPrompt';
import { ModalSandboxTransfer } from './SandboxTransfer';
import { ModalWebhookResult } from './WebhookResult';
import { ModalEventDetail } from './EventDetail';
import { ModalInvite } from './Invite';
import { ModalReturn } from './Return';
import { ModalClearTransfer } from './ClearTransfer';
import { ModalClearDisbursement } from './ClearDisbursement';
import { ModalUpdateDisbursement } from './UpdateDisbursement';
import { ModalEnableProduction } from './EnableProduction';
import { ModalCheckReturn } from './CheckReturn';
import { ModalEditCheck } from './EditCheck';
import { ModalErrorCheck } from './ErrorCheck';
import { ModalCreateStatement } from '~/components/Modal/CreateStatement';
// import { ModalCropImage } from './CropImage';

export * from './Authenticate';

export const modals = [
  <ModalAcceptPlatformInvite key="ModalAcceptPlatformInvite" />,
  <ModalAlert key="ModalAlert" />,
  <ModalAuthenticate key="ModalAuthenticate" />,
  <ModalCounterparty key="ModalCounterparty" />,
  <ModalCreateStatement key="ModalCreateStatement" />,
  <ModalPasswordPrompt key="ModalPasswordPrompt" />,
  <ModalSandboxTransfer key="ModalSandboxTransfer" />,
  <ModalWebhookResult key="ModalWebhookResult" />,
  <ModalEventDetail key="ModalEventDetail" />,
  <ModalInvite key="ModalInvite" />,
  <ModalReturn key="ModalReturn" />,
  <ModalCheckReturn key="ModalCheckReturn" />,
  <ModalClearTransfer key="ModalClearTransfer" />,
  <ModalClearDisbursement key="ModalClearDisbursement" />,
  <ModalUpdateDisbursement key="ModalUpdateDisbursement" />,
  <ModalEnableProduction key="ModalEnableProduction" />,
  <ModalEditCheck key="ModalEditCheck" />,
  <ModalErrorCheck key="ModalErrorCheck" />,
  // <ModalCropImage key="ModalCropImage" />,
];
