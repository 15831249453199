import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { Button, Chip, Icon } from '@column/column-ui-kit';
import { useNavigate } from 'react-router-dom';
import { EmptyState, PageHeader } from '~/components';
import { ReportingTable } from '~/components/ReportingTable';
import { ReportingSettlementReport } from '~/typings/API';
import { ROUTE } from '~/app/routes';
import { useModalStore } from '~/stores/Modal';
import { FeatureFlag, useFeatureFlag } from '~/lib/flags';

const Info = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 4px;
  max-width: 100%;
  padding-right: 8px;
`;

const InfoInner = styled.div`
  max-width: 100%;
  display: flex;
  align-items: center;
  line-height: 24px;
  gap: 4px;
`;

const Arrow = styled(Icon.ArrowRight)`
  display: inline-block;
  vertical-align: top;

  --icon-size: 16px;
  --icon-color: ${({ theme }) => theme.secondary.blendToBackground(800)};
`;

export const PageStatements: FC = () => {
  const openModal = useModalStore((state) => state.openModal);
  const navigate = useNavigate();
  const enableCustomCSVStatements = useFeatureFlag(FeatureFlag.EnableCustomCSVStatements);

  const overwriteData = useCallback((entry: ReportingSettlementReport, object: any) => {
    return !entry.statementSubjectDescription
      ? object
      : {
          ...object,
          information: (
            <Info>
              <InfoInner>
                {entry.fromDate} <Arrow /> {entry.toDate}
              </InfoInner>
              <Chip type="info">{entry.statementSubjectDescription}</Chip>
            </Info>
          ),
        };
  }, []);

  return (
    <>
      <PageHeader text="Statements" border={false} />
      <ReportingTable
        tableId="statements"
        filter={{ category: 'statement' }}
        empty={<EmptyState headline="No statements found" text="Wait for your first statements to get generated." />}
        visibleFiles={['pdf', 'csv']}
        hiddenFiles={['json', 'parquet', 'bai2']}
        onRowClick={(entry) => navigate(`${ROUTE.STATEMENTS}/${entry.id}`)}
        overwriteEntryObject={overwriteData}
        action={
          enableCustomCSVStatements && (
            <Button
              icon={<Icon.AnimationDownload />}
              size="small"
              variant="secondary"
              onClick={(e) => {
                e.stopPropagation();
                openModal('CreateStatement');
              }}
            >
              Export Custom CSV
            </Button>
          )
        }
      />
    </>
  );
};
