import React, { MouseEvent, ReactNode, useEffect, useMemo, useState, useRef } from 'react';
import { Cell, Column, Row, useRowSelect, useTable, TableProps as ReactTableProps } from 'react-table';
import styled, { css } from 'styled-components';
import { Button, Icon, Tooltip } from '@column/column-ui-kit';
import { PopoverFilter, PopoverFilterEntry, PopoverList } from './Popover';
import { SearchBar } from './SearchBar';
import { LogoLoading } from '~/elements';

export type { Cell };

export interface TableStyleProps {
  scrollable: boolean;
  maxHeight: string;
  isLoading: boolean;
}

export type TableColumn = Column & {
  width?: string;
};

export interface TableProps extends Partial<TableStyleProps> {
  columns: TableColumn[];
  data: any[];
  filter?: PopoverFilterEntry[];
  onFilterChange?: (filter: PopoverFilterEntry[]) => void;
  onSearchSubmit?: (value: string) => void;
  searchTooltip?: ReactNode;
  searchShowReset?: boolean;
  searchLoading?: boolean;
  showColumnsFilter?: boolean;
  className?: string;
  action?: ReactNode;
  hasNextPage?: boolean;
  onRowClick?: (row: Row) => void;
}

const TablePlaceholder = styled.div`
  transition: height 0.2s;
`;

const TableWrapper = styled.div<TableStyleProps>`
  position: relative;
  margin-bottom: 24px;
`;

const Toolbar = styled.div`
  display: flex;
  gap: 16px;
  padding: 0px 24px 12px;
  justify-content: space-between;
  background: ${({ theme }) => theme.background};
  border-bottom: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};
  position: sticky;
  top: 52px;
  z-index: 11;
`;

const StyledPopoverFilter = styled(PopoverFilter)`
  --popover-top: 36px;
  --popover-left: 24px;
  z-index: 10;
`;

const ShowColumnFilter = styled.div`
  position: relative;
`;

const StyledPopoverList = styled(PopoverList)`
  --popover-left: auto;
  --popover-right: 0;
  --popover-top: 36px;
`;

const Count = styled.div`
  width: 16px;
  height: 16px;
  background-color: ${({ theme }) => theme.primary.background};
  color: ${({ theme }) => theme.primary.foreground};
  font-size: 9px;
  font-weight: bold;
  line-height: 16px;
  text-align: center;
  border-radius: 3px;
`;

const Actions = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
`;

const StyledTable = styled.table<ReactTableProps & { columnsTemplate?: string }>`
  display: grid;
  will-change: height;
  transition: height 0.2s;
  margin: 0 12px;
  padding: 0 12px;
  column-gap: 16px;

  ${({ columnsTemplate }) =>
    columnsTemplate &&
    css`
      grid-template-columns: ${columnsTemplate};
    `};
`;

const StyledHead = styled.thead`
  display: grid;
  grid-template-columns: subgrid;
  grid-column: 1/-1;
  align-items: center;
`;

const StyledHeadRow = styled.tr`
  display: grid;
  grid-template-columns: subgrid;
  grid-column: 1/-1;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};
`;

const TableHeader = styled.td`
  padding: 8px 0;
  font-size: 14px;
  font-weight: 600;
  display: block;
  text-align: left;
  line-height: 16px;
  color: ${({ theme }) => theme.secondary.blendToBackground(600)};
`;

const StyledHeadCell = styled.th<{ showToolbar: boolean; isAction: boolean }>`
  padding: 12px 0;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  white-space: nowrap;
  color: ${({ theme }) => theme.secondary.blendToBackground(700)};

  ${({ isAction }) =>
    isAction &&
    css`
      & > div > svg {
        display: none;
      }
    `}
`;

const StyledBody = styled.tbody<TableStyleProps>`
  display: grid;
  grid-template-columns: subgrid;
  grid-column: 1/-1;
  transition: opacity 0.3s;

  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 0;
      transition-duration: 0.1s;
    `}

  ${({ scrollable }) =>
    scrollable &&
    css<TableStyleProps>`
      max-height: ${({ maxHeight }) => maxHeight};
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      flex-grow: 1;

      &::-webkit-scrollbar {
        display: none;
      }
    `}
`;

const EmptyRow = styled.td`
  display: grid;
  grid-template-columns: subgrid;
  grid-column: 1/-1;
  padding: 12px 0;
  font-size: 14px;
  font-style: italic;
  line-height: 24px;
  color: ${({ theme }) => theme.secondary.blendToBackground(600)};
`;

const StyledBodyRow = styled.tr<{ isClickable?: boolean }>`
  display: grid;
  grid-template-columns: subgrid;
  grid-column: 1/-1;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};
  position: relative;
  transition: border-color 0.2s;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -12px;
    right: -12px;
    border-radius: 8px;
    z-index: 0;
    opacity: 0;
    transition: opacity 0.2s;
  }

  ${({ isClickable }) =>
    isClickable &&
    css`
      &:hover {
        border-bottom-color: transparent;

        &:after {
          opacity: 1;
          background-color: ${({ theme }) => theme.secondary.blendToBackground(50)};
          box-shadow: 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(100)};

          ${({ theme }) =>
            theme.id !== 'default' &&
            css`
              background-color: ${theme.secondary.blendToBackground(25)};
            `}
        }

        td {
          position: relative;
          z-index: 1;
        }
      }
    `}
`;

const Padding = styled.div``;

const StyledBodyCell = styled.td<{
  isTitle: boolean;
  isAction: boolean;
  isClickable: boolean;
}>`
  padding: 12px 0;
  font-size: 14px;
  line-height: 16px;
  display: block;
  color: ${({ theme }) => theme.secondary.background};
  transition: background-color 0.1s;
  position: relative;

  ${({ isTitle }) =>
    isTitle &&
    css`
      font-size: 14px;
      font-weight: 500;
      color: ${({ theme }) => theme.foreground};
    `}

  ${({ isAction }) =>
    isAction &&
    css`
      & > div {
        display: flex;
        justify-content: flex-end;
      }
    `}

  ${({ isClickable }) =>
    isClickable &&
    css`
      cursor: pointer;
    `}
`;

const FullRow = styled.tr`
  display: grid;
  grid-column: 1/-1;
  align-items: center;
  text-align: center;
`;

const StyledLogoLoading = styled(LogoLoading)`
  top: calc(50% + 49px);
`;

export const Table: React.FC<TableProps> = (props) => {
  const tableRef = useRef<HTMLDivElement>(null);
  const headRef = useRef<HTMLTableSectionElement>(null);
  const bodyRef = useRef<HTMLTableSectionElement>(null);
  const [customList, setCustomList] = useState<string[] | boolean>(true);
  const [showFilterPopover, setShowFilterPopover] = useState<boolean>(false);
  const [showListPopover, setShowListPopover] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchFocus, setSearchFocus] = useState<boolean>(false);
  const [prev, setPrev] = useState<number | null>(null);

  const styleProps: TableStyleProps = {
    scrollable: props.scrollable ?? false,
    maxHeight: props.maxHeight ?? '544px',
    isLoading: loading,
  };

  const data = useMemo(() => props.data, [props.data]);

  const columns = useMemo(
    () =>
      props.columns.map((column: Column) => {
        if (typeof column.accessor === 'string') {
          column.id = column.accessor;
        }
        return column;
      }),
    [props.columns]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    setHiddenColumns,
    toggleHideAllColumns,
  } = useTable(
    {
      columns,
      data,
    },
    useRowSelect
  );

  const handleRowClick = (e: MouseEvent<HTMLTableRowElement>, row: Row) => {
    e.stopPropagation();

    if (props.onRowClick) {
      props.onRowClick(row);
    }
  };

  useEffect(() => {
    if (typeof customList === 'boolean') {
      toggleHideAllColumns(!customList);
    } else {
      setHiddenColumns(
        allColumns
          .filter((e: any) => !customList.includes(e.id))
          .map((e: any) => {
            if (e.id !== 'action') {
              return e.id;
            }
          })
      );
    }
  }, [customList]);

  useEffect(() => {
    if (!tableRef.current || !headRef.current || !bodyRef.current) {
      return;
    }

    const table = tableRef.current;
    const head = headRef.current;
    const body = bodyRef.current;

    if (props.isLoading) {
      setPrev(table.offsetHeight);
    }

    if (!props.isLoading) {
      if (!loading) {
        return;
      }

      if (prev && prev !== table.offsetHeight) {
        table.style.height = `${prev}px`;
      }

      setTimeout(() => {
        table.style.height = `${head.offsetHeight + body.offsetHeight}px`;

        setTimeout(() => {
          setLoading(false);
        }, 150);
      }, 0);

      return;
    }

    table.style.height = `${prev || table.offsetHeight}px`;

    setLoading(true);
  }, [props.isLoading]);

  const showToolbar = (props.filter && props.filter.length > 0) || props.showColumnsFilter || !!props.action;

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
  };

  const handleSearchSubmit = (value: string) => {
    if (props.onSearchSubmit) {
      props.onSearchSubmit(value);
    }
  };

  const getColumnsTemplate = (): string => {
    const columnsTemplate = props.columns
      .map((column: Column) => {
        if (column.width) {
          return `${column.width}`;
        }
        return 'minmax(100px, 1fr)';
      })
      .join(' ');
    return columnsTemplate;
  };

  return (
    <TableWrapper className={props.className} {...styleProps}>
      {showToolbar && (
        <Toolbar>
          {props.filter && props.filter.length > 0 && (
            <Button variant="secondary" size="small" icon={<Icon.Filter />} onClick={() => setShowFilterPopover(true)}>
              Add Filter
              {props.filter.filter((e: PopoverFilterEntry) => e.show === true).length > 0 && (
                <Count>{props.filter.filter((e: PopoverFilterEntry) => e.show === true).length}</Count>
              )}
            </Button>
          )}
          {props.filter && (
            <StyledPopoverFilter
              show={props.filter && props.filter.length > 0 && showFilterPopover}
              options={props.filter}
              onOptionChange={(filter: PopoverFilterEntry[]) => {
                if (props.onFilterChange) {
                  props.onFilterChange(filter);
                }
              }}
              onClose={() => setShowFilterPopover(false)}
              onDone={() => setShowFilterPopover(false)}
              onReset={() => {
                if (props.filter && props.onFilterChange) {
                  props.onFilterChange(
                    props.filter.map((e: PopoverFilterEntry) => {
                      e.show = false;
                      return e;
                    })
                  );
                }
              }}
            />
          )}
          {props.onSearchSubmit &&
            (props.searchTooltip ? (
              <Tooltip
                offsetX={-22}
                offsetArrowX={-92}
                placement="top-start"
                content={props.searchTooltip}
                isOpen={searchFocus && searchTerm.length < 1}
              >
                <SearchBar
                  value={searchTerm}
                  onChange={handleSearchChange}
                  onSearchSubmit={handleSearchSubmit}
                  onFocusChange={(value: boolean) => setSearchFocus(value)}
                  searchShowReset={props.searchShowReset}
                  placeholder="Search"
                  isLoading={props.searchLoading}
                />
              </Tooltip>
            ) : (
              <SearchBar
                value={searchTerm}
                onChange={handleSearchChange}
                onSearchSubmit={handleSearchSubmit}
                searchShowReset={props.searchShowReset}
                placeholder="Search"
                isLoading={props.searchLoading}
              />
            ))}
          <Actions>
            {props.showColumnsFilter && (
              <ShowColumnFilter>
                <Button
                  variant="subtle"
                  size="small"
                  icon={<Icon.TableEdit />}
                  onClick={() => setShowListPopover(true)}
                >
                  Settings
                </Button>
                <StyledPopoverList
                  show={showListPopover}
                  options={allColumns
                    .filter((e: any) => e.Header.length > 0 && e.id && e.id !== 'selection')
                    .map((entry: any) => ({
                      label: entry.Header,
                      id: entry.id,
                    }))}
                  active={customList}
                  onActiveChange={(options: string[] | boolean) => setCustomList(options)}
                  onClose={() => setShowListPopover(false)}
                  onDone={() => setShowListPopover(false)}
                  onReset={() => setCustomList(true)}
                />
              </ShowColumnFilter>
            )}
            {props.action}
          </Actions>
        </Toolbar>
      )}

      <TablePlaceholder ref={tableRef}>
        <StyledTable {...getTableProps()} columnsTemplate={getColumnsTemplate()}>
          <StyledHead ref={headRef}>
            {headerGroups.map((headerGroup) => {
              const { key: headerGroupKey, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
              return (
                <StyledHeadRow key={headerGroupKey} {...headerGroupProps}>
                  {(headerGroup.headers as any).map((column: any, index: number) => {
                    const { key: headerKey, ...headerProps } = column.getHeaderProps();
                    return (
                      <StyledHeadCell
                        key={headerKey}
                        showToolbar={showToolbar}
                        isAction={index === headerGroup.headers.length}
                        {...headerProps}
                      >
                        <Padding>{column.render('Header')}</Padding>
                      </StyledHeadCell>
                    );
                  })}
                </StyledHeadRow>
              );
            })}
          </StyledHead>
          <StyledBody {...styleProps} {...getTableBodyProps()} ref={bodyRef}>
            {rows.map((row: Row) => {
              prepareRow(row);
              const original = row.original as any;
              const { key: rowKey, ...rowProps } = row.getRowProps();
              if (original?.type === 'header') {
                return (
                  <FullRow key={rowKey} {...rowProps}>
                    <TableHeader colSpan={columns.length}>{original.name}</TableHeader>
                  </FullRow>
                );
              }
              return (
                <StyledBodyRow
                  key={rowKey}
                  {...rowProps}
                  isClickable={typeof props.onRowClick !== 'undefined'}
                  onClick={(e: MouseEvent<HTMLTableRowElement>) => handleRowClick(e, row)}
                >
                  {row.cells.map((cell, index) => {
                    const { key: cellKey, ...cellProps } = cell.getCellProps();
                    return (
                      <StyledBodyCell
                        key={cellKey}
                        isTitle={index === 0}
                        isAction={index === row.cells.length}
                        isClickable={typeof props.onRowClick !== 'undefined'}
                        {...cellProps}
                      >
                        <Padding>{cell.render('Cell')}</Padding>
                      </StyledBodyCell>
                    );
                  })}
                </StyledBodyRow>
              );
            })}
            {rows.length < 1 && (
              <FullRow>
                <EmptyRow colSpan={columns.length}>No entries found</EmptyRow>
              </FullRow>
            )}
          </StyledBody>
        </StyledTable>
      </TablePlaceholder>

      {loading && <StyledLogoLoading />}
    </TableWrapper>
  );
};
