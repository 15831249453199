import React, { FC, useEffect, useLayoutEffect } from 'react';
import { useNavigate, useParams, Outlet, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Icon, Button, Fade, InputElement, formatNumber } from '@column/column-ui-kit';
import { CustomPageContext } from '../../Layout/Route/CustomPage';
import { PageTabNavigation } from '../../Layout/Route/Page';
import { FormElement, FormLabel, FormText, Inner, Box } from '~/styles';
import { Copy, CopyInput, PageHeader, RestrictedBanner } from '~/components';
import { ROUTE } from '~/app/routes';
import { useNotificationStore } from '~/stores/Notification';
import { useModalStore } from '~/stores/Modal';
import { useSessionStore } from '~/stores/Session';
import { useBankAccount } from '~/hooks';
import { LogoLoading } from '~/elements';
import { BankAccount } from '~/repositories';

interface Params {
  id: string;
}

export interface BankAccountRouteProps {
  bankAccount: BankAccount;
}

const TabNavigation = styled(PageTabNavigation)`
  margin: 0;
`;

const StyledFade = styled(Fade)`
  margin-top: 10%;
`;

const Balance = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 20px;
  grid-column: 1 / 3;
`;

const FormTextAmount = styled(FormText)<{ isNegative?: boolean; isAvailable?: boolean }>`
  font-size: 24px;
  font-weight: 600;
  padding: 2px 0;
  color: ${({ theme }) => theme.secondary.background};

  ${({ isNegative }) =>
    isNegative &&
    css`
      color: ${({ theme }) => theme.danger.background};
    `}

  ${({ isAvailable, isNegative }) =>
    isAvailable &&
    !isNegative &&
    css`
      color: ${({ theme }) => theme.primary.background};
    `}
`;

const FormLabelAmount = styled(FormLabel)`
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
`;

const QuickNumbers = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
`;

const QuickNumber = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
`;

const StyledCopyInput = styled(CopyInput)`
  background-color: ${({ theme }) => theme.body};

  ${InputElement} {
    background-color: ${({ theme }) => theme.body};
    position: relative;
    overflow: hidden;
    margin-right: -8px;
    cursor: auto;
    color: ${({ theme }) => theme.secondary.blendToBackground(700)};
    mask-image: linear-gradient(90deg, rgb(0 0 0 / 100%) 75%, transparent 97.5%);
  }

  ${Copy} {
    z-index: 2;
  }
`;

export const PageBankAccountRoute: FC<CustomPageContext> = ({ entries }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    response: bankAccount,
    createRequest: fetchBankAccount,
    isLoading,
  } = useBankAccount({
    onError: (error) => {
      navigate(ROUTE.BANK_ACCOUNTS);

      addDangerNotification({
        content: error.message,
        display: 'page',
      });
    },
  });

  const { isSandbox, currentUser, currentPermission } = useSessionStore();
  const { addDangerNotification } = useNotificationStore();
  const openModal = useModalStore((state) => state.openModal);
  const { id } = useParams<keyof Params>() as Params;

  useLayoutEffect(() => {
    if (id) {
      fetchBankAccount({ id });
    }
  }, [id]);

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => ({
          isSandbox: state.isSandbox,
          isLoading: state.isLoading,
          defaultPlatformId: state.currentUser?.defaultPlatformId,
        }),
        () => {
          if (!currentUser) {
            return;
          }
          fetchBankAccount({ id });
        }
      ),
    []
  );

  return (
    <>
      <StyledFade show={isLoading || !bankAccount}>
        <LogoLoading />
      </StyledFade>
      <Fade show={!isLoading && !!bankAccount}>
        <PageHeader
          text={id ? bankAccount?.displayName || bankAccount?.description || 'Account' : 'Create Account'}
          tabs={<TabNavigation entries={entries} />}
        >
          {id && isSandbox && (
            <Button
              onClick={() =>
                openModal('SandboxTransfer', {
                  accountNumberId: bankAccount?.defaultAccountNumberId,
                  callback: () => {
                    setTimeout(() => {
                      fetchBankAccount({ id });

                      navigate(location.pathname, { state: { trigger: 'delayedFetch' } });
                    }, 200);
                  },
                })
              }
              size="small"
              icon={<Icon.Sandbox />}
            >
              Simulate Transfer
            </Button>
          )}
        </PageHeader>

        {currentPermission?.bankAccounts !== 'write' && (
          <Inner pb={0}>
            <RestrictedBanner />
          </Inner>
        )}

        {id && (
          <>
            <Inner>
              <Balance>
                <FormElement newRow>
                  <FormTextAmount isNegative={(bankAccount?.balances?.availableAmount ?? 0) < 0} isAvailable>
                    {formatNumber(bankAccount?.balances?.availableAmount)}
                  </FormTextAmount>
                  <FormLabelAmount>Available</FormLabelAmount>
                </FormElement>
                <FormElement>
                  <FormTextAmount isNegative={(bankAccount?.balances?.pendingAmount ?? 0) < 0}>
                    {formatNumber(bankAccount?.balances?.pendingAmount)}
                  </FormTextAmount>
                  <FormLabelAmount>Pending</FormLabelAmount>
                </FormElement>
                <FormElement>
                  <FormTextAmount isNegative={(bankAccount?.balances?.lockedAmount ?? 0) < 0}>
                    {formatNumber(bankAccount?.balances?.lockedAmount)}
                  </FormTextAmount>
                  <FormLabelAmount>Locked</FormLabelAmount>
                </FormElement>
                <FormElement>
                  <FormTextAmount isNegative={(bankAccount?.balances?.holdingAmount ?? 0) < 0}>
                    {formatNumber(bankAccount?.balances?.holdingAmount)}
                  </FormTextAmount>
                  <FormLabelAmount>Holding</FormLabelAmount>
                </FormElement>
              </Balance>
            </Inner>

            <Inner pt={0} pb={16}>
              <Box variant="secondary">
                <Inner>
                  <QuickNumbers>
                    <QuickNumber>
                      <Label>Account ID</Label>
                      <CopyInput value={bankAccount?.id} disabled showValueHover />
                    </QuickNumber>
                    <QuickNumber>
                      <Label>Routing</Label>
                      <CopyInput value={bankAccount?.routingNumber} disabled showValueHover />
                    </QuickNumber>
                    <QuickNumber>
                      <Label>Account</Label>
                      <CopyInput value={bankAccount?.defaultAccountNumber} disabled showValueHover />
                    </QuickNumber>
                  </QuickNumbers>
                </Inner>
              </Box>
            </Inner>
          </>
        )}
        {bankAccount && <Outlet context={{ bankAccount }} />}
      </Fade>
    </>
  );
};
