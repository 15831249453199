import React, { FC, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Button, Icon, Tooltip } from '@column/column-ui-kit';
import { FieldValues, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import cloneDeep from 'lodash.clonedeep';
import { ComponentLoader } from './Partials/ComponentLoader';
import { EditToolbar, Inner, Line } from '~/styles';
import { Section, PageHeader } from '~/components';
import { ROUTE } from '~/app/routes';
import {
  AchRepository,
  BookRepository,
  CheckDepositRequest,
  CheckIssueRequest,
  CheckRepository,
  CheckTransfer,
  CreateAchTransfer,
  CreateBookTransfer,
  CreateWireTransfer,
  TransferTypeID,
  WireRepository,
  transfers,
} from '~/repositories';
import { IconTransfers } from '~/elements';
import { useNotificationStore } from '~/stores/Notification';
import {
  checkIfRestrictedWrite,
  getDateFormat,
  mergeAccountAndCheckNumber,
  scrollToOffset,
  useDisplayFormErrors,
} from '~/util';
import { useSessionStore } from '~/stores/Session';

interface Params {
  type: TransferTypeID;
}

export interface LocationState {
  step?: 'index' | 'progress' | 'review';
  scanState?: 'complete';
  trigger?: 'formReset';
}

interface TransferTypeStatus {
  isActive: boolean;
  isDisabled: boolean;
  isRestricted: boolean;
}

interface CheckDepositAdditionalFields {
  accountNumber?: string;
  checkNumber?: string;
}

interface CheckDepositScannerFields {
  itemId?: number;
  state?: 'success' | 'error';
  imageFrontJPG?: string;
  imageBackJPG?: string;
}

export type CreateCheckDeposit = CheckDepositRequest & CheckDepositAdditionalFields;
export type CreateCheckDepositScanner = CreateCheckDeposit & CheckDepositScannerFields;

type IdempotenyKey = { idempotencyKey: string };

export type CreateTransfer = {
  ach: CreateAchTransfer & IdempotenyKey;
  book: CreateBookTransfer & IdempotenyKey;
  wire: CreateWireTransfer & IdempotenyKey;
  issueCheck: CheckIssueRequest & IdempotenyKey;
  rdc: CreateCheckDeposit;
  checkScanner: CreateCheckDeposit;
  checkScannerType: CreateCheckDepositScanner[];
};

const TRANSFER_ROUTES: Record<TransferTypeID, keyof CreateTransfer | undefined> = {
  ach: 'ach',
  book: 'book',
  wire: 'wire',
  'issue-check': 'issueCheck',
  rdc: 'rdc',
  check: 'checkScannerType',
  'intl-wire': undefined,
  realtime: undefined,
};

const Form = styled.form`
  margin: 0;
  display: flex;
  flex-direction: column;
`;

const TransferTypeList = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
`;

const TransferTypeEntry = styled.div<Partial<TransferTypeStatus>>`
  padding: 12px 16px;
  display: grid;
  justify-items: start;
  grid-gap: 12px;
  cursor: pointer;
  position: relative;
  border-radius: 8px;
  background-color: transparent;
  transition:
    background-color 0.2s,
    box-shadow 0.2s;
  box-shadow:
    0 -1px 1px rgba(255, 255, 255, 0) inset,
    ${({ theme }) => theme.style.buttonSecondaryBorderProperties}
      ${({ theme }) => theme.style.buttonSecondaryBorderWidth} ${({ theme }) => theme.style.buttonSecondaryBorderColor},
    0 1px 1px rgba(0, 0, 0, 0.1),
    0 1px 4px 0 transparent inset;

  ${({ isActive, isDisabled, isRestricted }) =>
    isActive
      ? css`
          background-color: ${({ theme }) =>
            theme.id !== 'dark' ? theme.background : theme.secondary.blendToBackground(25)};
          box-shadow:
            ${({ theme }) => theme.style.buttonSecondaryBorderProperties} 1.5px
              ${({ theme }) => theme.primary.background},
            0 0 0 var(--button-outline, 0px)
              var(--button-outline-color, ${({ theme }) => theme.style.buttonFocussedOutlineColor}),
            ${({ theme }) => theme.style.buttonSecondaryShadow};
        `
      : (isRestricted || isDisabled) &&
        css`
          cursor: not-allowed;
          background-color: ${({ theme }) =>
            theme.id !== 'dark' ? theme.secondary.blendToBackground(25) : theme.secondary.blendToBackground(-5)};
          box-shadow:
            0 -1px 1px rgba(255, 255, 255, 0) inset,
            ${({ theme }) => theme.style.buttonSecondaryBorderProperties}
              ${({ theme }) => theme.style.buttonSecondaryBorderWidth}
              ${({ theme }) => theme.style.buttonSecondaryBorderColor};
        `}

  ${({ isActive, isRestricted, isDisabled }) =>
    !isActive &&
    !isRestricted &&
    !isDisabled &&
    css`
      &:hover {
        background-color: ${({ theme }) =>
          theme.id !== 'dark' ? theme.secondary.blendToBackground(50) : theme.secondary.blendToBackground(25)};

        box-shadow:
          0 -1px 1px rgba(255, 255, 255, ${({ theme }) => (theme.id !== 'dark' ? '.75' : '.075')}) inset,
          ${({ theme }) => theme.style.buttonSecondaryHoveredBorderProperties}
            ${({ theme }) => theme.style.buttonSecondaryHoveredBorderWidth}
            ${({ theme }) => theme.secondary.blendToBackground(1100, 150)},
          0 1px 4px 0 ${({ theme }) => theme.secondary.blendToBackground(120)} inset;
      }
    `}
    ${({ isActive, isRestricted }) =>
    isActive &&
    isRestricted &&
    css`
      background-color: ${({ theme }) =>
        theme.id !== 'dark' ? theme.secondary.blendToBackground(25) : theme.secondary.blendToBackground(-5)};
      box-shadow: ${({ theme }) =>
        `${theme.style.buttonSecondaryBorderProperties} 1.5px ${theme.secondary.blendToBackground(750)}`};
    `}
`;

const TransferTypeIcon = styled.div<Partial<TransferTypeStatus>>`
  --icon-size: 20px;

  transition: color 0.2s;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${({ theme }) => theme.primary.background};
    `}
`;

const TransferTypeLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`;

const TransferEntryTime = styled.div`
  color: ${({ theme }) => theme.secondary.background};
  display: flex;
  gap: 4px;
  line-height: 14px;
  font-size: 11px;
  font-weight: 500;
  position: absolute;
  top: 12px;
  right: 16px;

  svg {
    --icon-size: 14px;
  }
`;

const TransferTypeComingSoon = styled(Tooltip)`
  position: absolute;
  top: 8px;
  right: 8px;
  border-radius: 6px;
  padding: 3px 6px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: ${({ theme }) => theme.primary.background};
  background-color: ${({ theme }) => theme.primary.blendToBackground(150)};
  display: flex;
`;

const TransferTypeRestricted = styled(Tooltip)`
  position: absolute;
  display: flex;
  top: 8px;
  right: 8px;
  border-radius: 6px;
  padding: 3px 6px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: ${({ theme }) => theme.danger.blendToBackground(1600)};
  background-color: ${({ theme }) => theme.danger.blendToBackground(250)};
`;

const RestrictedIcon = styled(Icon.AnimationLock)`
  width: 14px;
  height: 14px;
  display: inline-block;
  margin: 0 -3px;

  svg {
    width: 14px;
    height: 14px;
  }
`;

const ButtonRight = styled.div`
  display: flex;
  gap: 12px;
  margin-left: auto;
`;

export const PageTransfersAdd: FC = () => {
  const { addDangerNotification, addSuccessNotification } = useNotificationStore();
  const currentPermission = useSessionStore((sessionState) => sessionState.currentPermission);
  const navigate = useNavigate();
  const location = useLocation();
  const { type } = useParams<keyof Params>() as Params;
  const [createButtonLoading, setCreateButtonLoading] = useState<boolean>(false);
  const { displayFormErrors } = useDisplayFormErrors();

  const state = location.state as LocationState;

  const showCreateButton = useMemo(() => type !== 'check' || state?.step === 'review', [type, state]);

  const methods = useForm<CreateTransfer>({ mode: 'onChange' });
  const {
    handleSubmit,
    reset,
    trigger,
    formState: { errors: errorState },
    setValue,
  } = methods;

  const handleCancelButton = useCallback(
    (buttonLeft?: boolean) => {
      if (state?.step === 'review' && buttonLeft) {
        navigate(`${ROUTE.TRANSFERS}/edit/check`, {
          state: {
            step: 'index',
            scanState: undefined,
            trigger: 'formReset',
          },
        });
        return;
      }
      if (type !== 'check' || (state?.step && state?.step === 'index')) {
        navigate(ROUTE.TRANSFERS);
        return;
      }
      navigate(-1);
    },
    [type, state]
  );

  const handleReviewClick = useCallback(async () => {
    const validate = await trigger('checkScannerType');
    const errors = errorState?.checkScannerType;

    if (!validate) {
      if (!errors || !Array.isArray(errors) || errors.length < 1) {
        return;
      }
      errors.map((checkErrors, index) => {
        displayFormErrors(checkErrors, `notifications-check-${index}`);
      });
      return;
    }

    navigate(location.pathname, {
      state: {
        step: 'review',
        scanState: undefined,
      },
    });
  }, [location.pathname, type, errorState, displayFormErrors, trigger]);

  const handleTransferTypeClick = useCallback((id: string) => {
    navigate(`${ROUTE.TRANSFERS}/edit/${id}`);
  }, []);

  const createHandler = useCallback((promise: Promise<any>, message?: string) => {
    promise
      .then(() => {
        scrollToOffset(0);

        navigate(ROUTE.TRANSFERS, { state: { trigger: 'delayedFetch' } });

        addSuccessNotification({
          content: message ?? 'Transfer added',
          display: 'page',
        });
      })
      .catch((error) => {
        setCreateButtonLoading(false);

        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      });
  }, []);

  const mergeCheckDepositNumbers = useCallback((data: Partial<CreateCheckDepositScanner>) => {
    const { accountNumber, checkNumber, ...rest } = data;
    const { auxiliaryOnUs, onUs } = mergeAccountAndCheckNumber(
      rest.micrLine?.onUs ?? '',
      rest.micrLine?.auxiliaryOnUs ?? '',
      accountNumber ?? '',
      checkNumber ?? ''
    );

    rest.micrLine!.auxiliaryOnUs = auxiliaryOnUs;
    rest.micrLine!.onUs = onUs;

    return rest;
  }, []);

  const onSuccess = useCallback(
    (transfer: CreateTransfer) => {
      const data = cloneDeep<CreateTransfer>(transfer);

      if (createButtonLoading) {
        return;
      }

      setCreateButtonLoading(true);

      if (type === 'ach') {
        data.ach.currencyCode = 'USD';
        const { idempotencyKey, ...achData } = data.ach;

        if (!achData.effectiveDate || achData.effectiveDate.length < 1) {
          achData.effectiveDate = getDateFormat(new Date());
        }

        createHandler(AchRepository.create(achData, idempotencyKey));
        return;
      }
      if (type === 'book') {
        data.book.currencyCode = 'USD';
        const { idempotencyKey, ...bookData } = data.book;

        createHandler(BookRepository.create(bookData, idempotencyKey));

        return;
      }
      if (type === 'wire') {
        data.wire.currencyCode = 'USD';
        const { idempotencyKey, ...wireData } = data.wire;

        createHandler(WireRepository.create(wireData, idempotencyKey));

        return;
      }
      if (type === 'issue-check') {
        data.issueCheck.currencyCode = 'USD';
        const { idempotencyKey, ...issueCheckData } = data.issueCheck;
        if (issueCheckData.customizedCheckNumber !== undefined) {
          issueCheckData.customizedCheckNumber = Number(issueCheckData.customizedCheckNumber);
        }

        createHandler(CheckRepository.issue(issueCheckData, idempotencyKey));
        return;
      }
      if (type === 'rdc') {
        data.rdc.currencyCode = 'USD';

        createHandler(CheckRepository.deposit(mergeCheckDepositNumbers(data.rdc)));
        return;
      }
      if (type === 'check') {
        const requests: Promise<
          | {
              success: boolean;
              result: CheckTransfer;
            }
          | {
              success: boolean;
              error: any;
            }
        >[] = [];
        data.checkScannerType
          .filter((e) => e.state !== 'success')
          .forEach((checkData, index) => {
            const fields = mergeCheckDepositNumbers(checkData);

            delete fields.itemId;
            delete fields.state;
            delete fields.imageFrontJPG;
            delete fields.imageBackJPG;

            if (typeof fields === 'object' && fields.imageFront && fields.imageBack) {
              const request = CheckRepository.deposit(fields)
                .then((result) => {
                  setValue(`checkScannerType.${index}.state`, 'success');

                  return { success: true, result };
                })
                .catch((error) => {
                  addDangerNotification({
                    content: `Check #${index + 1}: ${error.message}`,
                    display: 'page',
                    index,
                  });

                  setValue(`checkScannerType.${index}.state`, 'error');

                  return { success: false, error };
                });

              requests.push(request);
            } else {
              addDangerNotification({
                content: `Check #${index + 1}: Missing image or data`,
                display: 'page',
                index,
              });

              setValue(`checkScannerType.${index}.state`, 'error');
            }
          });

        Promise.all(requests)
          .then((results) => {
            const allSuccess = results.every((result) => result.success);
            const addedChecks = results.filter((result) => result.success);

            if (addedChecks.length > 0) {
              addSuccessNotification({
                content:
                  addedChecks.length === 1 ? 'Check transfer added' : `${addedChecks.length} Check transfers added`,
                display: allSuccess ? 'page' : 'scanner-review',
              });
            }

            if (allSuccess) {
              navigate(ROUTE.TRANSFERS, { state: { trigger: 'delayedFetch' } });
            }
          })
          .catch((e) => console.error('An unexpected error occurred', e))
          .finally(() => {
            setCreateButtonLoading(false);
          });
      }
    },
    [type]
  );

  const onError: SubmitHandler<FieldValues> = useCallback(
    (errors) => {
      const id = TRANSFER_ROUTES[type];

      if ((id && (typeof errors[id] === 'undefined' || Object.entries(errors[id]).length < 1)) || !id) {
        return;
      }

      displayFormErrors(errors[id]);
    },
    [type]
  );

  useEffect(() => {
    if (state?.trigger === 'formReset') {
      reset();

      navigate(location.pathname, { replace: true });
    }
  }, [state, reset, location.pathname]);

  useLayoutEffect(() => {
    if (!Object.keys(TRANSFER_ROUTES).includes(type)) {
      navigate(`${ROUTE.TRANSFERS}/edit/${TRANSFER_ROUTES.ach}`);
    }
  }, [type]);

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSuccess, onError)}>
        <PageHeader text="Create Transfer">
          {state?.step === 'index' && (
            <Button onClick={() => handleCancelButton()} variant="secondary" size="small" type="button">
              Cancel
            </Button>
          )}
          {state?.step === 'review' && (
            <Button onClick={() => handleCancelButton(true)} variant="secondary" size="small" type="button">
              Cancel
            </Button>
          )}
          {showCreateButton && (
            <Button
              isLoading={createButtonLoading}
              isDisabled={checkIfRestrictedWrite(
                currentPermission,
                transfers.find((entry) => entry.id === type)?.permissionName
              )}
              size="small"
              icon={<Icon.Plus />}
            >
              Create Transfer
            </Button>
          )}
          {state?.step === 'progress' && state?.scanState === 'complete' && (
            <Button onClick={handleReviewClick} type="button" size="small">
              Review
            </Button>
          )}
        </PageHeader>

        <Section>
          <TransferTypeList>
            {transfers.map((entry) => {
              const isRestricted = checkIfRestrictedWrite(currentPermission, entry.permissionName);
              const IconElement = IconTransfers[entry.icon];

              return (
                <TransferTypeEntry
                  key={entry.id}
                  isActive={type === entry.id}
                  isRestricted={isRestricted}
                  isDisabled={entry.isComingSoon}
                  onClick={() => !entry.isComingSoon && !isRestricted && handleTransferTypeClick(entry.id)}
                >
                  <TransferTypeIcon>
                    <IconElement />
                  </TransferTypeIcon>
                  <TransferTypeLabel title={entry.label}>{entry.label}</TransferTypeLabel>
                  {entry.isInstant && !isRestricted && (
                    <TransferEntryTime>
                      Instant
                      <IconTransfers.Clock />
                    </TransferEntryTime>
                  )}
                  {entry.isComingSoon && (
                    <TransferTypeComingSoon content="Coming soon to the dashboard.">
                      {/* <ComingSoonIcon /> */}
                      API Only
                    </TransferTypeComingSoon>
                  )}
                  {isRestricted && !entry.isComingSoon && (
                    <TransferTypeRestricted offsetY={3} content="You do not have permission to use this transfer type.">
                      <RestrictedIcon />
                    </TransferTypeRestricted>
                  )}
                </TransferTypeEntry>
              );
            })}
          </TransferTypeList>
        </Section>

        <Line />
        <ComponentLoader path={Object.keys(TRANSFER_ROUTES).includes(type) ? type : 'ach'} />

        <Inner>
          <EditToolbar>
            {state?.step === 'index' && (
              <Button onClick={() => handleCancelButton()} variant="secondary" size="small" type="button">
                Cancel
              </Button>
            )}
            <ButtonRight>
              {state?.step === 'review' && (
                <Button onClick={() => handleCancelButton(true)} variant="secondary" size="small" type="button">
                  Cancel
                </Button>
              )}
              {showCreateButton && (
                <Button
                  isLoading={createButtonLoading}
                  isDisabled={checkIfRestrictedWrite(
                    currentPermission,
                    transfers.find((entry) => entry.id === type)?.permissionName
                  )}
                  size="small"
                  icon={<Icon.Plus />}
                >
                  Create Transfer
                </Button>
              )}
              {state?.step === 'progress' && state?.scanState === 'complete' && (
                <Button onClick={handleReviewClick} type="button" size="small">
                  Review
                </Button>
              )}
            </ButtonRight>
          </EditToolbar>
        </Inner>
      </Form>
    </FormProvider>
  );
};
