import React from 'react';
import styled, { css } from 'styled-components';
import { getDateLongUTC, getTimePassed, isValidDate } from '~/util';

interface RelativeTimeProps {
  timestamp: Date | string;
}

const Relative = styled.div`
  position: relative;
  opacity: 1;
  white-space: nowrap;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  transition: opacity 0.2s;
`;

export const Absolute = styled.div`
  position: absolute;
  display: inline-block;
  padding: 0 0 0 12px;
  top: 0;
  right: 0;
  white-space: nowrap;
  opacity: 0;
  z-index: 1;
  width: 0;
  overflow: hidden;
  background: ${({ theme }) => theme.secondary.blendToBackground(50)};
  transition: opacity 0.2s;

  ${({ theme }) =>
    theme.id !== 'default' &&
    css`
      background-color: ${theme.secondary.blendToBackground(25)};
    `}

  &:before {
    content: '';
    position: absolute;
    right: 100%;
    top: 0;
    bottom: 0;
    width: 32px;
    background-image: linear-gradient(
      90deg,
      ${({ theme }) => theme.secondary.blendToBackground(50, 0)} 0%,
      ${({ theme }) => theme.secondary.blendToBackground(50, 1000)} 100%
    );

    ${({ theme }) =>
      theme.id !== 'default' &&
      css`
        background-image: linear-gradient(
          90deg,
          ${theme.secondary.blendToBackground(25, 0)} 0%,
          ${theme.secondary.blendToBackground(25, 1000)} 100%
        );
      `}
  }
`;

const Wrapper = styled.div`
  position: relative;
  font-size: 12px;
  line-height: 24px;
  height: 24px;
  display: inline-block;

  &:hover {
    ${Absolute} {
      opacity: 1;
      width: auto;
      overflow: visible;
    }

    ${Relative} {
      opacity: 0;
    }
  }
`;

export const RelativeTime: React.FC<RelativeTimeProps> = (props) => {
  return (
    <Wrapper {...props}>
      {!isValidDate(props.timestamp) ? (
        '-'
      ) : (
        <>
          <Relative>{getTimePassed(props.timestamp)}</Relative>
          <Absolute>{isValidDate(props.timestamp) ? getDateLongUTC(props.timestamp) : '-'}</Absolute>
        </>
      )}
    </Wrapper>
  );
};
