import { gsap } from 'gsap';
import React, { CSSProperties, createRef, useEffect, useState, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { Icon, InputContainer, InputElement, InputWrapper, Tooltip } from '@column/column-ui-kit';
import { copyToClipboard } from '~/util';

export interface ApiKeyInputStyleProps {
  isVisible: boolean;
  isEmpty: boolean;
}

export interface ApiKeyInputProps extends Partial<ApiKeyInputStyleProps> {
  onChange?: (value: string) => void;
  onVisibleChange?: (value: boolean) => void;
  className?: string;
  value?: string;
  placeholder?: string;
  style?: CSSProperties;
}

const StyledApiKeyInput = styled(InputWrapper)`
  background: ${({ theme }) => theme.secondary.blendToBackground(50)};
  box-shadow: inset 0px 1px 1.5px rgba(38, 38, 44, 0.07);
  padding: 12px;
`;

const ApiKeyInputContainer = styled(InputContainer)`
  display: flex;
`;

const Fields = styled.div`
  --apikey-input-visible-position: 200%;
  --apikey-input-hidden-position: 50%;
  position: relative;
  flex-grow: 1;
  padding-left: 8px;
`;

const StyledInputlement = styled(InputElement)<ApiKeyInputStyleProps>`
  mask-position: var(--apikey-input-visible-position);
  mask-size: 200%;
  mask-image: linear-gradient(-90deg, transparent 0%, black 25%, black 75%, transparent 100%);
  mask-repeat: no-repeat;
`;

const ApiKeyInputElement = styled(StyledInputlement)<ApiKeyInputStyleProps>`
  position: absolute;
  top: 0;
  mask-position: var(--apikey-input-hidden-position);
`;

const Toggle = styled.div<ApiKeyInputStyleProps>`
  cursor: pointer;
  padding: 6px;
  margin: -8px -6px;

  svg {
    --icon-color: ${({ theme }) => theme.secondary.blendToBackground(500)};

    path {
      transition:
        fill 0.2s,
        stroke 0.2s;
    }
  }

  ${({ isEmpty }) =>
    isEmpty &&
    css`
      pointer-events: none;
      svg {
        --icon-color: ${({ theme }) => theme.secondary.blendToBackground(300)};
      }
    `}

  &:hover {
    svg {
      --icon-color: ${({ theme }) => theme.secondary.blendToBackground(800)};
    }
  }
`;

const Eye = styled(Icon.AnimationEye)`
  --eye-line-background-color: var(--input-background, ${({ theme }) => theme.secondary.blendToBackground(75)});
`;

const Copy = styled(Toggle)<ApiKeyInputStyleProps>`
  margin-left: auto;
`;

export const ApiKeyInput: React.FC<PropsWithChildren<ApiKeyInputProps>> = (props) => {
  const [isVisibleState, setIsVisibleState] = useState<boolean>(false);
  const [currentValue, setCurrentValue] = useState<string>(props.value ?? '');
  const [isTooltipShow, setIsTooltipShow] = useState<boolean>(false);

  const inputRef = createRef<HTMLInputElement>();
  const hiddenRef = createRef<HTMLInputElement>();
  const fieldsRef = createRef<HTMLDivElement>();

  const styleProps: ApiKeyInputStyleProps = {
    isVisible: isVisibleState,
    isEmpty: !currentValue,
  };

  const handleVisibleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const isVisible = !isVisibleState;
    if (props.onVisibleChange) {
      props.onVisibleChange(isVisible);
    }
    if (isVisible) {
      gsap.to(fieldsRef.current, {
        '--apikey-input-hidden-position': '-100%',
        duration: 0.4,
      });
      gsap.to(fieldsRef.current, {
        '--apikey-input-visible-position': '50%',
        duration: 0.4,
        delay: 0.1,
      });
    } else {
      gsap.set(fieldsRef.current, {
        '--apikey-input-hidden-position': '200%',
      });
      gsap.to(fieldsRef.current, {
        '--apikey-input-visible-position': '-100%',
        duration: 0.4,
      });
      gsap.to(fieldsRef.current, {
        '--apikey-input-hidden-position': '50%',
        duration: 0.4,
        delay: 0.1,
        onComplete: (target: HTMLDivElement) => {
          gsap.set(target, {
            '--apikey-input-visible-position': '200%',
            '--apikey-input-hidden-position': '50%',
          });
        },
        onCompleteParams: [fieldsRef.current],
      });
    }
    setIsVisibleState(isVisible);
  };

  const handleCopyClick = () => {
    setIsTooltipShow(true);
    copyToClipboard(currentValue);
    setTimeout(() => setIsTooltipShow(false), 2000);
  };

  useEffect(() => {
    setCurrentValue(props.value ?? '');
  }, [props.value]);

  useEffect(() => {
    if (props.onChange) {
      props.onChange(currentValue);
    }
  }, [currentValue]);

  return (
    <StyledApiKeyInput style={props.style} className={props.className} {...styleProps}>
      <ApiKeyInputContainer {...styleProps}>
        <Toggle onClick={handleVisibleClick} {...styleProps}>
          <Eye line={!isVisibleState} />
        </Toggle>
        <Fields ref={fieldsRef}>
          <ApiKeyInputElement
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCurrentValue(e.target.value)}
            ref={hiddenRef}
            value={currentValue}
            type="password"
            autoComplete="off"
            readOnly
            placeholder={props.placeholder}
            {...styleProps}
          />
          <StyledInputlement
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCurrentValue(e.target.value)}
            ref={inputRef}
            value={currentValue}
            type="text"
            autoComplete="off"
            readOnly
            placeholder={props.placeholder}
            {...styleProps}
          />
        </Fields>
        <Copy onClick={handleCopyClick} {...styleProps}>
          <Tooltip content="Copied!" isOpen={isTooltipShow}>
            <Icon.CopyCode />
          </Tooltip>
        </Copy>
      </ApiKeyInputContainer>
      {props.children}
    </StyledApiKeyInput>
  );
};
