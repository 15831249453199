import React, { useRef, useEffect, useState, ReactNode } from 'react';
import styled from 'styled-components';
import { elementScrollIntoView } from 'seamless-scroll-polyfill';
import { Notification, NotificationProps } from './Notification';
import { NotificationGroupType, NotificationType, useNotificationStore } from '~/stores/Notification';
import { inViewport } from '~/util';

interface NotificationEntry extends NotificationProps {
  id: string;
  content: string | ReactNode;
}

export interface NotificationListProps {
  display: NotificationGroupType;
  className?: string;
  disableScroll?: boolean;
}

const Wrapper = styled.div`
  scroll-margin-top: 0;
`;

const StyledNotification = styled(Notification)`
  margin: 16px 0 0;

  :last-child {
    margin-bottom: 16px;
  }
`;

export const NotificationList: React.FC<NotificationListProps> = ({ display, disableScroll, className }) => {
  const [entries, setEntries] = useState<NotificationType[]>([]);
  const removeNotification = useNotificationStore((state) => state.removeNotification);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(
    () =>
      useNotificationStore.subscribe(
        (state) => {
          const getOpenNotification = [...state.getOpenNotification(display)];

          return {
            triggerNotification: state.triggerNotification,
            getOpenNotification,
          };
        },
        ({ triggerNotification, getOpenNotification }) => {
          const wrapper = wrapperRef.current;

          setEntries([...getOpenNotification]);

          if (triggerNotification && triggerNotification === display && wrapper && !disableScroll) {
            if (inViewport(wrapper)) {
              return;
            }
            elementScrollIntoView(wrapper, { behavior: 'smooth' });
          }
        },
        {
          fireImmediately: true,
        }
      ),
    [display]
  );

  return (
    <Wrapper className={className} ref={wrapperRef}>
      {entries.map((entry: NotificationEntry) => (
        <StyledNotification
          key={entry.id}
          color={entry.color}
          variant={entry.variant}
          withClose={entry.withClose}
          shouldClose={entry.shouldClose}
          actionLabel={entry.actionLabel}
          onActionClick={entry.onActionClick}
          smoothShow
          onClose={() => removeNotification(entry.id)}
        >
          {entry.content}
        </StyledNotification>
      ))}
    </Wrapper>
  );
};
