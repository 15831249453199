import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Icon, Dropdown, Chip, DropdownOption, formatNumber } from '@column/column-ui-kit';
import { LoanRepository } from '~/repositories';
import { PageHeader, EmptyState, PaginationWrapper, PopoverFilterEntry, TableColumn } from '~/components';
import { ROUTE } from '~/app/routes';
import { formatString } from '~/util';
import { RelativeTime } from '~/elements';

const Description = styled.div`
  display: grid;
  grid-gap: 2px;
`;

const Note = styled.div`
  line-height: 16px;
  font-size: 11px;
  font-weight: 500;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
`;

export const handleFetchDisbursements = async (params: any, navigate: NavigateFunction, addLoanLink?: boolean) => {
  return LoanRepository.getAllDisbursements(params).then((response) => {
    const entries: any = [];

    if (response.disbursements.length) {
      response.disbursements.map((entry: any) => {
        const options: DropdownOption[] = [];

        if (addLoanLink) {
          options.push({
            label: 'Loan',
            onClick: () => navigate(`${ROUTE.LOANS}/edit/${entry.loanId}`),
          });
        }

        entries.push({
          id: entry.id,
          description: [entry?.amount ?? 0, entry.description],
          status: entry.status,
          created: entry.createdAt,
          action: (
            <Dropdown
              isInside
              positionRight
              options={[
                ...options,
                {
                  label: 'Edit',
                  onClick: () => navigate(`${ROUTE.LOANS}/disbursements/edit/${entry.id}`),
                },
              ]}
              variant="dots"
            />
          ),
        });
      });
    }
    return { entries, hasMore: Boolean(response?.hasMore) };
  });
};

export const disbursementColumns: TableColumn[] = [
  {
    Header: 'Description',
    accessor: 'description',
    width: 'minmax(300px, auto)',
    Cell: (current) => (
      <Description>
        {formatNumber(current.value[0])}
        {current.value[1] && <Note>{current.value[1]}</Note>}
      </Description>
    ),
  },
  {
    Header: 'Status',
    width: 'minmax(min-content, 100px)',
    Cell: (current) => <Chip>{formatString(current.value)}</Chip>,
    accessor: 'status',
  },
  {
    Header: 'Created',
    accessor: 'created',
    sortType: 'datetime',
    width: 'min-content',
    Cell: (current) => <RelativeTime timestamp={current.value} />,
  },
  {
    Header: '',
    width: 'min-content',
    accessor: 'action',
  },
];

export const disbursementFilter: PopoverFilterEntry[] = [
  {
    id: 'created',
    label: 'Created',
    type: 'date',
  },
];

export const PageLoansDisbursementOverview: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <PageHeader text="Disbursements" border={false}>
        <Button onClick={() => navigate(ROUTE.LOANS)} icon={<Icon.HandCoins />} size="small" variant="secondary">
          Loans
        </Button>
        <Button onClick={() => navigate(`${ROUTE.LOANS}/disbursements/create`)} icon={<Icon.Plus />} size="small">
          Create Disbursement
        </Button>
      </PageHeader>

      <PaginationWrapper
        tableId="disbursements"
        fetch={(o) => handleFetchDisbursements(o, navigate, true)}
        columns={disbursementColumns}
        filter={disbursementFilter}
        empty={
          <EmptyState headline="No disbursements found" text="Create your first disbursement to get started.">
            <Button onClick={() => navigate(`${ROUTE.LOANS}/disbursements/create`)} size="small" icon={<Icon.Plus />}>
              Create Disbursement
            </Button>
          </EmptyState>
        }
        rowClick={(row) => {
          navigate(`${ROUTE.LOANS}/disbursements/edit/${row.original.id}`);
        }}
      />
    </>
  );
};
