import { gsap } from 'gsap';
import React, { useRef, useEffect, useLayoutEffect, PropsWithChildren, FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Sidebar } from './Sidebar';
import { PlatformStatus, User, Events } from './Toolbar';
import { helpSidebars, modals } from '~/components';
import { useScrollPosition } from '~/hooks';
import { useHelpSidebarStore } from '~/stores/HelpSidebar';
import { useScrollStore } from '~/stores/Scroll';
import { useSessionStore } from '~/stores/Session';

interface LocationState {
  from?: string;
}

const Wrapper = styled.div<{ $showSandboxBanner?: boolean }>`
  --sidebar-width: 248px;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
  flex-grow: 1;
  display: grid;
  grid-template-columns: auto;
  padding-left: var(--sidebar-width);
`;

const Content = styled.div`
  --content-o: 0;
  opacity: var(--content-o);
  min-height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

const Toolbar = styled.div<{ isScrolled: boolean }>`
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  padding-left: 20px;
`;

const Panel = styled.div`
  padding: 8px 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  flex: auto;
`;

const PageFrame = styled.main`
  position: relative;
  display: flex;
  flex: auto;
  flex-direction: column;
  padding: 0;
  overflow: auto;
  background: ${({ theme }) => theme.background};
  border-radius: 8px;
  margin: 0 8px 8px 0;
  box-shadow:
    0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(1100, 50)},
    0 1px 2px rgba(0, 0, 0, 0.1);

  ${({ theme }) =>
    theme.id !== 'default' &&
    css`
      background: ${() => theme.background};
      box-shadow:
        0 0 0 1px ${() => theme.white.blendToBackground(1000, 80)},
        0 1px 2px rgba(0, 0, 0, 0.15);
    `};
`;

const Inner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Layout: FC<PropsWithChildren> = ({ children }) => {
  const setPageFrameScroll = useScrollStore((s) => s.setPageFrameScroll);
  const { openHelpSidebar, openHelpSidebars } = useHelpSidebarStore();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const toolbarRef = useRef<HTMLDivElement>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const { scrollY } = useScrollPosition(wrapperRef);
  const { currentPlatform, showSandboxBanner } = useSessionStore((s) => ({
    currentPlatform: s.currentPlatform,
    showSandboxBanner: s.showSandboxBanner,
  }));

  const location = useLocation();
  const navigate = useNavigate();

  const state = location.state as LocationState;

  useEffect(() => {
    setPageFrameScroll(scrollY);
  }, [scrollY]);

  useEffect(() => {
    if (state && state.from === 'register') {
      openHelpSidebar('Onboarding');
      navigate(location.pathname);
    }
    if (state && state.from === 'auth') {
      gsap.to(sidebarRef.current, {
        '--sidebar-o': 1,
        duration: 0.4,
        ease: 'none',
        onComplete: () => {
          navigate(location.pathname);
        },
      });
      gsap.to(contentRef.current, {
        '--content-o': 1,
        duration: 0.4,
        ease: 'none',
      });
    } else {
      gsap.set(sidebarRef.current, {
        '--sidebar-o': 1,
      });
      gsap.set(contentRef.current, {
        '--content-o': 1,
      });
    }
  }, [location]);

  useLayoutEffect(() => {
    const wrapper = wrapperRef.current;

    if (!wrapper) {
      return;
    }

    wrapper.scrollTo({ top: 0 });
  }, [wrapperRef, location]);

  return (
    <Wrapper id="scroll-container" $showSandboxBanner={showSandboxBanner}>
      <Sidebar ref={sidebarRef} />
      <Content ref={contentRef}>
        <Toolbar ref={toolbarRef} isScrolled={scrollY > 0 && openHelpSidebars.length < 1}>
          <Panel>
            {currentPlatform && <Events />}
            {currentPlatform && <PlatformStatus />}
            <User />
          </Panel>
        </Toolbar>
        <PageFrame ref={wrapperRef}>
          <Inner>{children}</Inner>
        </PageFrame>
      </Content>

      {modals}
      {helpSidebars}
    </Wrapper>
  );
};
